<template>
  <div class="question-detail">
    <div class="banner">
      <img src="../assets/images/rediandayi.jpg" alt="" width="100%">
    </div>
    <div class="content">
      <div class="title">{{result.category}}</div>
      <div><!--<span>来源：税屋</span> <span>作者：彭怀文</span> --><span>人气：{{result.attention}}</span> <span>时间：{{result.createTime ? result.createTime.substring(0, 10): ''}}</span></div>
      <div class="row"><span>问：</span><span>{{result.content}}</span></div>
      <div class="row"></div>
      <div class="row" v-for="(item, index) in result.answers" :key="index">
        <div><span>解答{{values[index]}}：</span></div>
        <div class="" v-html="item.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'questionDetail',
  props: ['id'],
  data () {
    return {
      result: {
      },
      values: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十']
    }
  },
  methods: {
    getContent () {
      this.API.getHotDetail({ id: this.id }).then(({ code, data }) => {
        this.result = data
      })
    }
  },
  created () {
    this.getContent()
  }
}
</script>

<style scoped lang="scss">
.question-detail {
  background: #ffffff;
  .content{
    width: 1200px;
    margin: 0 auto;
    padding: 30px 0;
    line-height: 28px;
    .title{
      margin: 10px 0;
      font-size: 22px;
    }
    .row{
      text-align: left;
      text-indent: 28px;
    }
  }
}
</style>
